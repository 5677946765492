import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo/Seo';
import Paso1 from '../assets/images/rule.inline.svg';
import Code from '../assets/images/code.inline.svg';
import Access from '../assets/images/access.inline.svg';
import Documentation from '../assets/images/documentation.inline.svg';
import './index.css';
import DemoModal from '../components/Modal/Modal';

function IndexPage() {
  return (
    <Layout>
      <Seo title="Hyperbloq" />
      <div className="banner">
        <div className="row">
          <div className="col-sm-12 col-md-offset-1 col-md-10">
            <div className="call-to-action text-center">
              <h1>
                Desarrolla e integra tus aplicaciones de forma rápida y segura
              </h1>
              <h3>
                Acelera tu proceso de desarrollo con nuestra plataforma low
                code, Hyperbloq te permite configurar y gestionar APIs con
                facilidad.
              </h3>
              <a
                href="https://calendly.com/hyperbloq-tomas/30min"
                target="_blank"
                className="btn btn-secondary"
                rel="noreferrer"
              >
                Agendar una demo
              </a>
            </div>
            <div className="banner-image">
              <StaticImage
                src="../../static/images/banner-new.png"
                placeholder="blurred"
                loading="eager"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                alt="Banner Image"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="funcionalidades">
        <div className="row center-xs not-pb">
          <div className="col-sm-12">
            <div className="call-to-action">
              <h2>Funcionalidades</h2>
            </div>
          </div>
        </div>
        <div className="row middle-md mobile-reverse">
          <div className="col-sm-12 col-md-offset-1 col-md-5">
            <div className="img-section">
              <StaticImage
                src="../../static/images/colecciones2.png"
                placeholder="blurred"
                loading="eager"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                alt="Colections Image"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="text-section">
              <h3>Colecciones</h3>
              <p>
                Almacena tantos datos como necesites, de forma segura <br />
                <strong>Sin limite de registros.</strong>
              </p>
            </div>
          </div>
        </div>

        <div className="row middle-xs">
          <div className="col-sm-12 col-md-offset-1 col-md-5">
            <div className="text-section">
              <h3>Autenticación</h3>
              <p>Gestiona Identidades y Accesos de forma simple.</p>
              <p>
                Autorización basada en roles, inicio de sesión único (SSO) y
                autenticación multifactor (MFA).
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-5">
            <div className="img-section">
              <StaticImage
                src="../../static/images/login2.png"
                placeholder="blurred"
                loading="eager"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                alt="Colections Image"
              />
            </div>
          </div>
        </div>

        <div className="row middle-xs mobile-reverse">
          <div className="col-sm-12 col-md-offset-1 col-md-5">
            <div className="img-section">
              <StaticImage
                src="../../static/images/archivos2.png"
                placeholder="blurred"
                loading="eager"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                alt="Colections Image"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-5">
            <div className="text-section">
              <h3>Archivos</h3>
              <p>
                Almacenamiento para todo tipo de archivos. Integrado con
                Autenticación y Colecciones para la gestión de la privacidad y
                acceso.
              </p>
            </div>
          </div>
        </div>

        <div className="row middle-xs">
          <div className="col-sm-12 col-md-offset-1 col-md-5">
            <div className="text-section">
              <h3>Integraciones</h3>
              <p>
                Más de 200 integraciones con aplicaciones de terceros: ERPs,
                Servicios de pago, CRMs y otros.
              </p>
              <p>Integraciones via REST, XML, SFTP, SOAP, SSH disponibles.</p>
            </div>
          </div>
          <div className="col-sm-12 col-md-5">
            <div className="img-section">
              <StaticImage
                src="../../static/images/integraciones2.png"
                placeholder="blurred"
                loading="eager"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                alt="Colections Image"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="stepper" id="stepper">
        <div className="row around-xs">
          <div className="col-xs-12">
            <h2 className="text-center">
              Comienza a usar Hyperbloq en solo 3 pasos
            </h2>
          </div>
        </div>
        <div className="stepper-section">
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <div className="card-stepper">
                <div className="card-stepper-image">
                  <Paso1 alt="" className="stepper-img" />
                </div>
                <div className="card-stepper-title">
                  <h4>
                    <strong>1.</strong> Crea tu espacio de trabajo
                  </h4>
                </div>
                <div className="card-stepper-text">
                  <p>Define los servicios que utilizaran tu aplicación.</p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="card-stepper">
                <div className="card-stepper-image">
                  <Code alt="" className="stepper-img" />
                </div>
                <div className="card-stepper-title">
                  <h4>
                    <strong>2.</strong> Define tus reglas
                  </h4>
                </div>
                <div className="card-stepper-text">
                  <p>
                    A través del panel de administración puedes configurar tu
                    modelo de datos, definir la privacidad de la información y
                    gestionar los perfiles de usuario.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-4">
              <div className="card-stepper">
                <div className="card-stepper-image">
                  <Access alt="" className="stepper-img" />
                </div>
                <div className="card-stepper-title">
                  <h4>
                    <strong>3.</strong> Comienza a usar tus APIs
                  </h4>
                </div>
                <div className="card-stepper-text">
                  <p>
                    Comparte el link a la documentación auto generada con tu
                    equipo y comienza a conectar tus aplicaciones.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row pad-r-l">
            <div className="col-xs-12 text-center">
              <a
                href="https://calendly.com/hyperbloq-tomas/30min"
                target="_blank"
                className="btn btn-primary"
                rel="noreferrer"
              >
                Agendar una demo
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="planes" id="planes">
        <div className="row around-xs">
          <div className="col-xs-12">
            <h2 className="text-center">Planes</h2>
          </div>
        </div>
        <div className="cards">
          <div className="card">
            <h3>Desarrollador</h3>
            <h4>
              <strong>Gratis</strong>
            </h4>
            <ul className="list-price">
              <li className="item-tick">
                <strong>1</strong> Espacio de Trabajo
              </li>
              <li className="item-tick">
                <strong>10 GB</strong> Tráfico /mes
              </li>
              <li className="item-tick">
                <strong>3</strong> Roles de usuario predefinidos
              </li>
              <li className="item-tick">
                <strong>5.000</strong> Usuarios registrados por espacio
              </li>
              <li className="item-tick">
                Emails de registro de usuario y recuperar contraseña
              </li>
              <li className="item-tick">
                <strong>3 GB</strong> de almacenamiento total
              </li>
              <li className="item-tick">
                <strong>5 GB</strong> de almacenamiento de archivos
              </li>
              <li className="item-tick">
                Acceso a <strong>Hyperbloq Studio</strong> (Beta)
              </li>
            </ul>
            <DemoModal />
          </div>
          <div className="card">
            <h3>Corporativo</h3>
            <h4>
              <strong>A medida</strong>
            </h4>
            <ul className="list-price">
              <li className="item-tick">
                <strong>9</strong> Colaboradores
              </li>
              <li className="item-tick">
                <strong>10</strong> Espacios de trabajo
              </li>
              <li className="item-tick">
                <strong>250 GB</strong> Tráfico /mes
              </li>
              <li className="item-tick">
                <strong>100 GB</strong> de almacenamiento de archivos
              </li>
              <li className="item-tick">
                <strong>10.000</strong> Usuarios registrados por espacio
              </li>
              <li className="item-tick">
                <strong>3</strong> Roles de usuario predefinidos
              </li>
              <li className="item-tick">
                Emails de registro de usuario y recuperar contraseña
              </li>
              <li className="item-tick">
                Personalización de template de email
              </li>
              <li className="item-tick">
                Integraciones con <strong>Sendgrid o Mailjet</strong>
              </li>
              <li className="item-tick">
                <strong>25 GB</strong> de almacenamiento total
              </li>
              <li className="item-tick">
                Sincronización de hasta 3 colecciones
              </li>
              <li className="item-tick">
                Acceso a <strong>Hyperbloq Studio</strong> (Beta)
              </li>
            </ul>
            <a
              href="https://calendly.com/hyperbloq-tomas/30min"
              target="_blank"
              className="btn btn-secondary-fill btn-price"
              rel="noreferrer"
            >
              Agendar una demo
            </a>
          </div>
        </div>
      </div>

      <div className="apifica">
        <div className="row middle-xs">
          <div className="col-sm-12 col-md-6 col-lg-5">
            <div className="text-section">
              <h3>APIfica tu organización</h3>
              <p>
                Integra fácilmente diversas fuentes de información, permitiendo
                un acceso rápido a datos actualizados.
              </p>
              <p>
                Ten la tranquilidad de que tus datos están seguros, y siempre
                disponibles.
              </p>
              <a
                href="https://calendly.com/hyperbloq-tomas/30min"
                target="_blank"
                className="btn btn-primary"
                rel="noreferrer"
              >
                Agendar una demo
              </a>
            </div>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-offset-1 col-lg-5">
            <div className="card-documents card-stepper">
              <Documentation alt="" className="icon-circle-white" />
              <h4 className="white-texts">Documentación Autogenerada</h4>
              <p className="white-texts">
                Tus APIs definidas y listas para compartir
              </p>
            </div>
            <div className="card-access card-stepper">
              <Access alt="" className="icon-circle-white" />
              <h4 className="white-texts">Control de accesos y permisos</h4>
              <p className="white-texts">
                Define quien y que puede conectarse de forma simple
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="team" id="team">
        <div className="row">
          <div className="col-xs-12">
            <h2 className="text-center">
              Construido por y para equipos de tecnología
            </h2>
          </div>
        </div>
        <div className="row between-md">
          <div className="col-xs-12 col-sm-6 col-md-3">
            <div className="card-team">
              <div className="card-team-image">
                <StaticImage
                  className="logos-clientes"
                  src="../../static/images/lionel.png"
                  placeholder="blurred"
                  loading="eager"
                  quality={100}
                  formats={['auto', 'webp', 'avif']}
                  alt=""
                />
              </div>
              <div className="card-team-title">
                <h5>
                  <a href="https://www.linkedin.com/in/lionelcuevas/">
                    Lionel Cuevas
                  </a>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <div className="card-team">
              <div className="card-team-image">
                <StaticImage
                  className="logos-clientes"
                  src="../../static/images/tomas.png"
                  placeholder="blurred"
                  loading="eager"
                  quality={100}
                  formats={['auto', 'webp', 'avif']}
                  alt=""
                />
              </div>
              <div className="card-team-title">
                <h5>
                  <a href="https://www.linkedin.com/in/tomasromano1/">
                    Tomas Romano
                  </a>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <div className="card-team">
              <div className="card-team-image">
                <StaticImage
                  className="logos-clientes"
                  src="../../static/images/pablo.png"
                  placeholder="blurred"
                  loading="eager"
                  quality={100}
                  formats={['auto', 'webp', 'avif']}
                  alt=""
                />
              </div>
              <div className="card-team-title">
                <h5>
                  <a href="https://www.linkedin.com/in/pbullian/">
                    Pablo Bullian
                  </a>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <div className="card-team">
              <div className="card-team-image">
                <StaticImage
                  className="logos-clientes"
                  src="../../static/images/florencia.png"
                  placeholder="blurred"
                  loading="eager"
                  quality={100}
                  formats={['auto', 'webp', 'avif']}
                  alt=""
                />
              </div>
              <div className="card-team-title">
                <h5>
                  <a href="https://www.linkedin.com/in/florencia-algieri/">
                    Florencia Algieri
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="respaldo" id="respaldo-chile">
        <div className="row center-xs middle-md">
          <div className="col-xs-12">
            <h2 className="text-center">Con el respaldo de</h2>
            <StaticImage
              className="logo-chile"
              src="../../static/images/start-up-chile.png"
              placeholder="blurred"
              loading="eager"
              quality={100}
              formats={['auto', 'webp', 'avif']}
              alt="Start Up Chile Logo"
            />
          </div>
        </div>
      </div>
      <div id="contactanos" className="demo">
        <div className="row middle-xs">
          <div className="col-lg-5">
            <div className="img-section">
              <StaticImage
                src="../../static/images/isologo.png"
                placeholder="blurred"
                loading="eager"
                quality={100}
                formats={['auto', 'webp', 'avif']}
                alt=""
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <h2 className="sm-center">Contactános</h2>
            <form
              name="reachOutForm"
              method="POST"
              data-netlify="true"
              action="/thank-you"
            >
              <input type="hidden" name="form-name" value="reachOutForm" />
              <label htmlFor="name">
                {' '}
                Nombre<span>*</span>
              </label>
              <input
                type="text"
                required
                id="name"
                name="name"
                placeholder="Nombre"
              />
              <input type="hidden" name="lastname" value="" />
              <label htmlFor="email">
                {' '}
                Email<span>*</span>
              </label>
              <input type="email" required name="email" placeholder="Email" />
              <label htmlFor="comment">
                {' '}
                Mensaje<span>*</span>
              </label>
              <textarea
                name="message"
                required
                placeholder="Comentario"
                rows="5"
              />
              <input type="hidden" name="how-meet-us" value="" />
              <button type="submit" className="btn btn-primary">
                Enviar
              </button>
            </form>
          </div>
        </div>
      </div>
      <footer>
        <div className="col-sm-12">
          <p className="white-text">
            © {new Date().getFullYear()} &middot;
            {` `}
            Hyperbloq LLC
          </p>
        </div>
      </footer>
    </Layout>
  );
}

export default IndexPage;
