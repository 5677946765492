import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Header from '../Header/Header';

import './layout.css';
import 'flexboxgrid/dist/flexboxgrid.min.css';

const propTypes = {
  children: PropTypes.node.isRequired,
};

function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header />
      {children}
    </>
  );
}

Layout.propTypes = propTypes;
export default Layout;
