import React, { useState, useEffect } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Scrollspy from 'react-scrollspy';

import MenuIcon from '../../assets/images/icon-menu.inline.svg';
import CloseIcon from '../../assets/images/icon-close.inline.svg';
import LogoHeader from '../../assets/images/logo.inline.svg';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [navColor, setNavColor] = useState('transparent');
  const [firstLinkColor, setFirstLinkColor] = useState('default');
  const menuToggle = () => setMenuOpen(!menuOpen);

  const changeNavColor = () => {
    let color = 'white';
    if (window.scrollY > 50) {
      color = 'white';
    }
    setNavColor(color);
    let linkColor = '';
    if (window.scrollY < 100) {
      linkColor = 'default';
    }
    setFirstLinkColor(linkColor);
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNavColor);
  });

  return (
    <header id="top">
      <nav className={`navbar nav-${navColor}`}>
        <div className="nav-logo" id="top">
          <LogoHeader alt="" className="logo-header-desktop" />
          <ul className={`nav-list ${menuOpen ? 'active' : ''}`} id="navi-list">
            <Scrollspy items={['top']} currentClassName="active" offset={500}>
              <li className={`list-item ${setNavColor}`}>
                <AnchorLink
                  to="#top"
                  duration={3000}
                  onAnchorLinkClick={() => setMenuOpen(false)}
                >
                  <LogoHeader alt="" className="logo-header" />
                </AnchorLink>
              </li>
            </Scrollspy>
          </ul>
        </div>
        <ul className={`nav-list ${menuOpen ? 'active' : ''}`} id="navi-list">
          {/* <Scrollspy
            items={['top', 'funcionalidades', 'planes', 'demo', 'contactanos']}
            currentClassName="active"
            offset={500}
          > */}
          <li className={`list-item ${firstLinkColor}`}>
            <AnchorLink
              to="#funcionalidades"
              title="Funcionalidades"
              duration={3000}
              onAnchorLinkClick={() => setMenuOpen(false)}
            />
          </li>
          <li className={`list-item ${firstLinkColor}`}>
            <AnchorLink
              to="#planes"
              title="Planes"
              duration={3000}
              onAnchorLinkClick={() => setMenuOpen(false)}
            />
          </li>
          <li className="list-item">
            <a
              href="https://documenter.getpostman.com/view/4594074/2s93RUtWqS#intro"
              target="_blank"
              aria-label="Documentación"
              rel="noreferrer"
            >
              Documentación
            </a>
          </li>
          <li className={`list-item ${firstLinkColor}`}>
            <AnchorLink
              to="#contactanos"
              title="Contactános"
              duration={3000}
              onAnchorLinkClick={() => setMenuOpen(false)}
            />
          </li>
          <li className="list-item">
            <a
              href="https://calendly.com/hyperbloq-tomas/30min"
              target="_blank"
              className="btn-navbar"
              rel="noreferrer"
            >
              Agendar una demo
            </a>
          </li>
          {/* </Scrollspy> */}
        </ul>
        {menuOpen ? (
          <CloseIcon className="menu" onClick={menuToggle} />
        ) : (
          <MenuIcon className="menu" onClick={menuToggle} />
        )}
      </nav>
    </header>
  );
}

export default Header;
