import React, { useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';

import Close from '../../assets/images/close.inline.svg';
import './modal.css';

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
};

const selectStyles = {
  right: '10px',
};

function DemoModal() {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
  }

  function closeModal() {
    setIsOpen(false);
  }

  const options = [
    { value: 'google', label: 'Google' },
    { value: 'social', label: 'Redes Sociales' },
    { value: 'referred', label: 'Referido' },
  ];

  return (
    <>
      <button onClick={openModal} className="btn btn-secondary-outline btn-price">
        Comenzar
      </button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style={customStyles}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="demo-form">
          <h3 className="text-center">Comienza ahora.</h3>
          <p className="text-center">
            Completa tus datos para obtener un usuario Desarrollador de forma
            gratuita.
          </p>
          <button onClick={closeModal} style={closeButtonStyles}>
          <Close alt="" className="icon-circle-white" />
        </button>
          <form
            name="reachOutForm"
            method="POST"
            data-netlify="true"
            action="/thank-you"
          >
            <input type="hidden" name="form-name" value="reachOutForm" />
            <label htmlFor="name">
              {' '}
              Nombre<span>*</span>
            </label>
            <input
              type="text"
              required
              id="name"
              name="name"
              placeholder="Nombre"
            />
            <label htmlFor="lastname">
              {' '}
              Apellido<span>*</span>
            </label>
            <input
              type="text"
              required
              id="lastname"
              name="lastname"
              placeholder="Apellido"
            />
            <label htmlFor="email">
              {' '}
              Email<span>*</span>
            </label>
            <input type="email" required name="email" placeholder="Email" />
            <label htmlFor="how-meet-us" className="select-form">
              {' '}
              ¿Cómo nos conociste?<span>*</span>
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Seleccione"
              isClearable={false}
              isRtl={false}
              isSearchable={false}
              name="how-meet-us"
              id="how-meet-us"
              options={options}
            />
            <input type="hidden" name="message" value="" />
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default DemoModal;
